.question-enter {
  opacity: 0;
  position: absolute;
  transform: translate3d(40px, 0, 0);
}
.question-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 300ms, transform 300ms;
  width: 100%;
}
.question-exit {
  opacity: 1;
  position: absolute;
}
.question-exit-active {
  opacity: 0;
  transform: translate3d(-40px, 0, 0);
  transition: opacity 300ms, transform 300ms;
}

.question-reverse-enter {
  opacity: 0;
  position: absolute;
  transform: translate3d(-40px, 0, 0);
}
.question-reverse-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 300ms, transform 300ms;
}
.question-reverse-exit {
  opacity: 1;
  position: absolute;
}
.question-reverse-exit-active {
  opacity: 0;
  transform: translate3d(40px, 0, 0);
  transition: opacity 300ms, transform 300ms;
}
